import axios from 'axios';
import errorResponseHandler from '../error_handler/ErrorHandler';
import { processQueue, errorMessageHandle } from '../refresh_token/refresh_token_handler';
import updateRefreshToken from '../refresh_token/update_refresh_token';

import AuthAxiosInstance from '../axios_config/AuthAxiosInstance';
import CommonAxiosInstance from '../axios_config/AxiosInstance';

var ErrorMessage = "";

let isRefreshing = false;
let failedQueue = [];

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ERM_API_URL,
    timeout: 0,
    headers: {
        'Accept-Version': 1,
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8',
    }
});

AxiosInstance.interceptors.request.use(
    (config) => {
        let token = window.sessionStorage.getItem('endevo_accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },

);

// apply interceptor on response
AxiosInstance.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;

        // check for errorHandle config
        if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
            return Promise.reject(error);
        }

        if (error.response) {
            //refresh token when hit 401
            if (error.response.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject })
                    }).then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return AxiosInstance(originalRequest);
                    }).catch(err => {
                        return err
                    })
                }

                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise(function (resolve, reject) {
                    axios({
                        method: "post",
                        url: process.env.REACT_APP_AUTH_API_URL + "Token/RefreshToken",
                        data: {
                            userID: window.sessionStorage.getItem("endevo_userID"),
                            refreshToken: sessionStorage.getItem("endevo_refreshToken")
                        },
                        headers: {
                            'Authorization': 'Bearer ' + window.sessionStorage.getItem('endevo_accessToken')
                        }
                    }).then(async response => {

                        if (response.data !== "error") {
                            sessionStorage.setItem('endevo_accessToken', response.data.accessToken);
                            sessionStorage.setItem('endevo_refreshToken', response.data.refreshToken);

                            //Change Authentication API Authorization
                            AuthAxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;

                            //Change API Authorization
                            AxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;

                            //Common API Authorization
                            CommonAxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;

                            originalRequest.headers['Authorization'] = 'Bearer ' + response.data.accessToken;

                            processQueue(null, response.data.accessToken);
                            resolve(AxiosInstance(originalRequest));
                        }
                    })
                        .catch(async (err) => {
                            processQueue(err, null);
                            reject(err);
                        })
                        .then(() => { isRefreshing = false });

                });

                return Promise.reject(error);

            } else {
                errorMessageHandle(error)
            }
        }
    }
);

export default AxiosInstance;
