const menuItems = {
    items: [
        {
            id: 'navigation',
            languageKey: 'COMMON.Navigation',
            title: 'Navigation',
            type: 'group',
            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'home',
                    languageKey: 'COMMON.Home',
                    title: 'Home',
                    type: 'collapse',
                    applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                    screenID: '',
                    icon: 'feather icon-home',
                    children: [
                        {
                            id: 'default',
                            languageKey: 'COMMON.Default',
                            title: 'Default',
                            type: 'item',
                            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                            screenID: '03dec1dd-28ed-4671-83c2-f46890c0524e',
                            url: '/app/home/dashboard'
                        },
                        //{
                        //    id: 'tasks_board',
                        //    languageKey: 'COMMON.Task Board',
                        //    title: 'Task Board',
                        //    type: 'item',
                        //    applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                        //    screenID: '14446d33-c166-422d-b418-321cec38b945',
                        //    url: '/app/home/task_board'
                        //},
                        {
                            id: 'calendar',
                            languageKey: 'COMMON.Calendar',
                            title: 'Calendar',
                            type: 'item',
                            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                            screenID: '184c2012-18a5-4916-8617-860e23f4f120',
                            url: '/app/home/calendar'
                        }
                    ]
                }
            ]
        },
        {
            id: 'erm_admin',
            languageKey: 'COMMON.ERM Admin',
            title: 'ERM Admin',
            type: 'group',
            applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'erm_admin',
                    languageKey: 'COMMON.ERM Admin',
                    title: 'ERM Admin',
                    type: 'collapse',
                    applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        //{
                        //    id: 'level_management',
                        //    languageKey: 'ERMADMIN.Level Management',
                        //    title: 'Level Management',
                        //    type: 'item',
                        //    applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                        //    screenID: '3d47654b-cd7e-4e7c-8b8e-a9e312596897',
                        //    url: '/app/erm_admin/level_management'
                        //},
                        {
                            id: 'workflow_management',
                            languageKey: 'ERMADMIN.Workflow Management',
                            title: 'Workflow Management',
                            type: 'item',
                            applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                            screenID: '69eb3c00-305a-4b9d-b6a8-91ba084674bf',
                            url: '/app/erm_admin/workflow_management/workflow_listing'
                        },
                        {
                            id: 'scorecard_management',
                            languageKey: 'ERMADMIN.Scorecard Management',
                            title: 'Scorecard Management',
                            type: 'item',
                            applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                            screenID: '7707210b-00fb-4637-985e-328fc9ee88c9',
                            url: '/app/erm_admin/scorecard_management/scorecard_listing'
                        },
                        {
                            id: 'frequency_management',
                            languageKey: 'ERMADMIN.Frequency Management',
                            title: 'Frequency Management',
                            type: 'item',
                            applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                            screenID: '7707210b-00fb-4637-985e-328fc9ee88c9',
                            url: '/app/erm_admin/frequency_management/frequency_listing'
                        },
                        {
                            id: 'setup_heatmap',
                            languageKey: 'ERMADMIN.Setup Heatmap',
                            title: 'Setup Heatmap',
                            type: 'item',
                            applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                            screenID: '1e49ac0e-ce50-4e3c-af42-20d5b63584ba',
                            url: '/app/erm_admin/heatmap_management/heatmap_management'
                        },
                        {
                            id: 'library_management',
                            languageKey: 'ERMADMIN.Library Management',
                            title: 'Library Management',
                            type: 'item',
                            applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                            screenID: '8b0e0297-3b64-4593-afda-e1d1f6e1a445',
                            url: '/app/erm_admin/library_management'
                        },
                        {

                            id: 'erm_access_rights_setup',
                            languageKey: 'ERMADMIN.Access Rights Setup',
                            title: 'Access Rights Setup',
                            type: 'collapse',
                            applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                            screenID: '',
                            icon: '',
                            children: [
                                {
                                    id: 'setup_user_access_rights',
                                    languageKey: 'ERMADMIN.Setup User Access Rights',
                                    title: 'Setup User Access Rights',
                                    type: 'item',
                                    applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                                    screenID: 'b16563d7-0d8b-445e-bb91-cd66916137be',
                                    url: '/app/erm_admin/scorecard_access_rights/scorecard_access_rights_user_management/user_listing'
                                },
                                {
                                    id: 'setup_role_access_rights',
                                    languageKey: 'ERMADMIN.Setup Role Access Rights',
                                    title: 'Setup Role Access Rights',
                                    type: 'item',
                                    applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                                    screenID: 'edee84b4-e46b-4c93-bcb5-76afcc8b1549',
                                    url: '/app/erm_admin/scorecard_access_rights/scorecard_access_rights_role_management/role_listing'
                                }
                                //{
                                //    id: 'setup_workflow_access_rights',
                                //    languageKey: 'ERMADMIN.Setup Workflow Access Rights',
                                //    title: 'Setup Workflow Access Rights',
                                //    type: 'item',
                                //    applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                                //    screenID: '7c5ca438-e072-4454-ba8e-5e896997e6ef',
                                //    url: '/app/erm_admin/scorecard_access_rights/scorecard_access_rights_workflow_management/workflow_listing'
                                //}
                            ]
                        }
                    ]
                }
            ]
        },
        {
            id: 'erm_user',
            languageKey: 'COMMON.ERM',
            title: 'ERM',
            type: 'group',
            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'erm_user',
                    languageKey: 'COMMON.ERM',
                    title: 'ERM',
                    type: 'collapse',
                    applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        {
                            id: 'scorecard_selection',
                            languageKey: 'ERM.Select Scorecard',
                            title: 'Scorecard Selection',
                            type: 'item',
                            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                            screenID: '',
                            url: '/app/erm_user/scorecard_selection'
                        },
                        {
                            id: 'risk_register',
                            languageKey: 'ERM.Risk Register',
                            title: 'Risk Register',
                            type: 'item',
                            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                            screenID: '2d0fce9b-cf40-428c-8413-3ac417afc7e6',
                            url: '/app/erm_user/risk_register'
                        },
                        {
                            id: 'declaration',
                            languageKey: 'ERM.Declaration',
                            title: 'Declaration',
                            type: 'item',
                            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                            screenID: 'b0ab79a0-fb22-4a5b-86ff-58c435a3dc73',
                            url: '/app/erm_user/scorecard_submission'
                        },
                        {
                            id: 'historical_approval_data',
                            languageKey: 'ERM.Historical Approval Data',
                            title: 'Historical Approval Data',
                            type: 'item',
                            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                            screenID: '41d794c9-fbb1-474b-956c-39c3c56dd574',
                            url: '/app/erm_user/historical_approval_data'
                        }
                    ]
                }
            ]
        },
        {
            id: 'report',
            languageKey: 'COMMON.Report',
            title: 'Report',
            type: 'group',
            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'report',
                    languageKey: 'COMMON.Report',
                    title: 'Report',
                    type: 'collapse',
                    applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        {
                            id: 'report',
                            languageKey: 'COMMON.Report',
                            title: 'Report',
                            type: 'item',
                            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                            screenID: 'ac7cd7cb-8f7e-4374-bb13-291c4e2ab381',
                            url: '/app/erm_report/report'
                        },
                        {
                            id: 'report_query',
                            languageKey: 'COMMON.Report Query',
                            title: 'Report Query',
                            type: 'item',
                            applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                            screenID: 'efb1722e-02c7-488f-8425-cbc9b5b43f44',
                            url: '/app/erm_report/report_query'
                        }
                    ]
                }
            ]
        }
    ]
};

export default menuItems;
