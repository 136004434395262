import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import GuestGuard from "./components/Auth/GuestGuard";
import AuthGuard from "./components/Auth/AuthGuard";
import AccessRightsGuard from "./components/Auth/AccessRightsGuard";

import CustomSecurityEncrypt from "./components/Security/CustomSecurityEncrypt";

import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<Loader />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    <CustomSecurityEncrypt {...props}>
                                        {route.routes
                                            ? renderRoutes(route.routes)
                                            : <Component {...props}
                                                applicationID={route.applicationID}
                                                screenID={route.screenID} />}
                                    </CustomSecurityEncrypt>
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        applicationID: '',
        screenID: '',
        component: lazy(() => import('./views/Auth/Signin/SignIn'))
    },
    {
        exact: true,
        path: '/404',
        applicationID: '',
        screenID: '',
        component: lazy(() => import('./views/Errors/NotFound404'))
    },
    {
        path: '*',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/home/dashboard',
                applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                screenID: '03dec1dd-28ed-4671-83c2-f46890c0524e',
                component: lazy(() => import('./views/Home/Dashboard/DashDefault'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/home/user_profile',
                applicationID: '',
                screenID: '',
                component: lazy(() => import('./views/Common/User_Profile'))
            },
            //{
            //    exact: true,
            //    guard: AccessRightsGuard,
            //    path: '/app/home/task_board',
            //    applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
            //    screenID: '14446d33-c166-422d-b418-321cec38b945',
            //    component: lazy(() => import('./views/Home/Task_Board/TaskBoard'))
            //},
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/home/calendar',
                applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                screenID: '184c2012-18a5-4916-8617-860e23f4f120',
                component: lazy(() => import('./views/Home/Calendar/Calendar'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_user/scorecard_selection',
                applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                screenID: '',
                component: lazy(() => import('./views/ERM/ERM_User/Scorecard_Selection'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_user/risk_register',
                applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                screenID: '2d0fce9b-cf40-428c-8413-3ac417afc7e6',
                component: lazy(() => import('./views/ERM/ERM_User/Risk_Register'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_user/risk_register_form',
                applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                screenID: '033f9add-7f10-4eb9-a6f3-96ca48c4207f',
                component: lazy(() => import('./views/ERM/ERM_User/Risk_Register_Form'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_user/scorecard_submission',
                applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                screenID: 'b0ab79a0-fb22-4a5b-86ff-58c435a3dc73',
                component: lazy(() => import('./views/ERM/ERM_User/Scorecard_Submission'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_user/scorecard_approval',
                applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                screenID: 'fd622aed-f3fb-4190-a51b-dd815f9f5206',
                component: lazy(() => import('./views/ERM/ERM_User/Scorecard_Approval'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_user/historical_approval_data',
                applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                screenID: '41d794c9-fbb1-474b-956c-39c3c56dd574',
                component: lazy(() => import('./views/ERM/ERM_User/Historical_Approval_Data'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/level_management',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: '3d47654b-cd7e-4e7c-8b8e-a9e312596897',
                component: lazy(() => import('./views/ERM/ERM_Admin/Level_Management'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/workflow_management/workflow_listing',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: '69eb3c00-305a-4b9d-b6a8-91ba084674bf',
                component: lazy(() => import('./views/ERM/ERM_Admin/Workflow_Management/Workflow_Listing'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/workflow_management/workflow_details',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: 'bcc182e4-d079-4a82-a590-97410f02c682',
                component: lazy(() => import('./views/ERM/ERM_Admin/Workflow_Management/Workflow_Details'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/scorecard_management/scorecard_listing',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: '7707210b-00fb-4637-985e-328fc9ee88c9',
                component: lazy(() => import('./views/ERM/ERM_Admin/Scorecard_Management/Scorecard_Listing'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/scorecard_management/scorecard_details',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: 'b9897da1-f420-459b-bfb5-c9578a2b955a',
                component: lazy(() => import('./views/ERM/ERM_Admin/Scorecard_Management/Scorecard_Details'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/scorecard_access_rights/scorecard_access_rights_user_management/user_listing',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: 'b16563d7-0d8b-445e-bb91-cd66916137be',
                component: lazy(() => import('./views/ERM/ERM_Admin/Scorecard_Access_Rights/Scorecard_Access_Rights_User_Management/User_Listing'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/scorecard_access_rights/scorecard_access_rights_user_management/user_details',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: 'b16563d7-0d8b-445e-bb91-cd66916137be',
                component: lazy(() => import('./views/ERM/ERM_Admin/Scorecard_Access_Rights/Scorecard_Access_Rights_User_Management/User_Details'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/scorecard_access_rights/scorecard_access_rights_role_management/role_listing',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: 'edee84b4-e46b-4c93-bcb5-76afcc8b1549',
                component: lazy(() => import('./views/ERM/ERM_Admin/Scorecard_Access_Rights/Scorecard_Access_Rights_Role_Management/Role_Listing'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/scorecard_access_rights/scorecard_access_rights_role_management/role_details',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: '30eada5e-1bb2-4854-a971-243746c7666a',
                component: lazy(() => import('./views/ERM/ERM_Admin/Scorecard_Access_Rights/Scorecard_Access_Rights_Role_Management/Role_Details'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/scorecard_access_rights/scorecard_access_rights_workflow_management/workflow_listing',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: '7c5ca438-e072-4454-ba8e-5e896997e6ef',
                component: lazy(() => import('./views/ERM/ERM_Admin/Scorecard_Access_Rights/Scorecard_Access_Rights_Workflow_Management/Workflow_Listing'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/scorecard_access_rights/scorecard_access_rights_workflow_management/workflow_details',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: '7c5ca438-e072-4454-ba8e-5e896997e6ef',
                component: lazy(() => import('./views/ERM/ERM_Admin/Scorecard_Access_Rights/Scorecard_Access_Rights_Workflow_Management/Workflow_Details'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/frequency_management/frequency_listing',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: '7707210b-00fb-4637-985e-328fc9ee88c9',
                component: lazy(() => import('./views/ERM/ERM_Admin/Frequency_Management/Frequency_Listing'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/frequency_management/frequency_details',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: 'b9897da1-f420-459b-bfb5-c9578a2b955a',
                component: lazy(() => import('./views/ERM/ERM_Admin/Frequency_Management/Frequency_Details'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/heatmap_management/heatmap_management',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: '1e49ac0e-ce50-4e3c-af42-20d5b63584ba',
                component: lazy(() => import('./views/ERM/ERM_Admin/Heatmap_Management'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_admin/library_management',
                applicationID: '553c9d59-9bd6-47e5-9d56-3b5a1caf2184',
                screenID: '8b0e0297-3b64-4593-afda-e1d1f6e1a445',
                component: lazy(() => import('./views/ERM/ERM_Admin/Library_Management'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_report/report',
                applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                screenID: 'ac7cd7cb-8f7e-4374-bb13-291c4e2ab381',
                component: lazy(() => import('./views/ERM/ERM_Report/Report'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/erm_report/report_query',
                applicationID: '22ba2e9f-cce3-4107-9645-491f7ae2a834',
                screenID: 'efb1722e-02c7-488f-8425-cbc9b5b43f44',
                component: lazy(() => import('./views/ERM/ERM_Report/Report_Query'))
            },
            {
                path: '*',
                exact: true,
                applicationID: '',
                screenID: '',
                component: () => <Redirect to={BASE_URL} />
            }
        ]
    }
];

export default routes;
